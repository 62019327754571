.info-light{
    font-weight: 600;
    color: white; 
}

.info-dark{
    font-weight: 600;
    color: black; 
}

.blink{
    animation: ping 0.75s steps(1, end) infinite;
}

@keyframes ping {
  50%, 100% {
    color: white; 
  }
}