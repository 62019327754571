@tailwind base;
@tailwind components;

@layer base {
    @font-face {
        font-family: GretaStd;
        font-weight: 400;
        src: url('./fonts/GretaSansStd_suite_OTF/GretaSansStd-Regular.otf');
    }
    @font-face {
        font-family: GretaStd;
        font-weight: 500;
        src: url('./fonts/GretaSansStd_suite_OTF/GretaSansStd-Medium.otf');
    }
    @font-face {
        font-family: GretaStd;
        font-weight: 600;
        src: url('./fonts/GretaSansStd_suite_OTF/GretaSansStd-SemiBold.otf');
    }
    @font-face {
        font-family: GretaStd;
        font-weight: 700;
        src: url('./fonts/GretaSansStd_suite_OTF/GretaSansStd-Bold.otf');
    }
    @font-face {
        font-family: GretaStd;
        font-weight: 800;
        src: url('./fonts/GretaSansStd_suite_OTF/GretaSansStd-Heavy.otf');
    }


    @font-face {
        font-family: GretaExt;
        font-weight: 400;
        src: url('./fonts/GretaSansStd_suite_OTF/GretaSansExtendedStd-Regular.otf');
    }
    @font-face {
        font-family: GretaExt;
        font-weight: 500;
        src: url('./fonts/GretaSansStd_suite_OTF/GretaSansExtendedStd-Medium.otf');
    }
    @font-face {
        font-family: GretaExt;
        font-weight: 600;
        src: url('./fonts/GretaSansStd_suite_OTF/GretaSansExtendedStd-SemiBold.otf');
    }
    @font-face {
        font-family: GretaExt;
        font-weight: 700;
        src: url('./fonts/GretaSansStd_suite_OTF/GretaSansExtendedStd-Bold.otf');
    }
}


@tailwind utilities;

html {
    @apply bg-grey;
    scroll-behavior: smooth;
    /* hyphens: auto; */
}

.light-link {
    @apply underline underline-offset-2 hover:text-white transition-all;
}

p {
    hyphens: auto;
}
